/* eslint-disable */
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import firebase from 'firebase/compat/app';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import 'firebase/compat/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory } from 'react-router-dom';

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
};

export default function LoginDialog({ open, onClose }) {
  // eslint-disable-next-line no-unused-vars
  const [authUser, authLoading] = useAuthState(firebase.auth());
  const history = useHistory();

  return (
    <Dialog open={open}>
      {authLoading ? (
        <>Načítání...</>
      ) : (
        <>
          {authUser ? (
            <>
              <DialogTitle>Přihlášen jako {authUser.displayName}</DialogTitle>
              <DialogContent>{authUser.email}</DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    firebase.auth().signOut();
                    history.push('/');
                  }}
                >
                  Odhlásit se
                </Button>
                <Button onClick={onClose}>Zavřít</Button>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogTitle>Přihlášení</DialogTitle>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
              <DialogActions>
                <Button onClick={onClose}>Zavřít</Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
}
