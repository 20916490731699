import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Skeleton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    maxWidth: '100vw',
  },
  media: {
    width: '500px',
    paddingTop: '57%',
  },
}));

export default function Slide() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Card>
        <Skeleton variant="rectangular">
          <div className={classes.media} />
        </Skeleton>
        <CardContent>
          <Skeleton>
            <Typography gutterBottom variant="h5" component="h2">
              Lorem ipsum dolor sit amet
            </Typography>
          </Skeleton>
          <Skeleton>
            <Typography variant="body2" color="textSecondary" component="p">
              Lorem ipsum dolor
            </Typography>
          </Skeleton>
        </CardContent>
      </Card>
    </div>
  );
}
