/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import { Feedback, Person } from '@mui/icons-material';
import LoginDialog from './LoginDialog';

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  toolbarIcons: {
    marginLeft: 'auto',
  },
}));

export default function Layout({ children, authUser }) {
  const classes = useStyles();
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  return (
    <>
      <LoginDialog open={loginDialogOpen} onClose={() => setLoginDialogOpen(false)} />
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <Link to="/" style={{ textDecoration: 'none', color: 'unset' }}>
            <Typography variant="h6" component="h1">
              Shadowloop
            </Typography>
          </Link>
          <div className={classes.toolbarIcons}>
            <Button
              className={classes.login}
              color="inherit"
              size="large"
              onClick={() => setLoginDialogOpen(true)}
              startIcon={<Person />}
            >
              {authUser ? authUser.displayName : <>Přihlásit se</>}
            </Button>
            <a
              href={process.env.REACT_APP_FEEDBACK_URL}
              style={{ textDecoration: 'none', color: 'unset', marginLeft: 16 }}
            >
              <IconButton color="inherit" size="large">
                <Feedback />
              </IconButton>
            </a>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
      {children}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
