/* eslint-disable react/jsx-no-bind,react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { readRemoteFile } from 'react-papaparse';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { ref, getDownloadURL } from 'firebase/storage';
import Slide from './Slide';
import LoadingSlide from './LoadingSlide';
import Controls from './Controls';

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:0px) and (orientation: landscape)': {
      minHeight: 'calc(100vh - 48px)',
    },
    '@media (min-width:600px)': {
      minHeight: 'calc(100vh - 64px)',
    },
    minHeight: 'calc(100vh - 56px)',
  },
}));

function shuffleArray(array) {
  // eslint-disable-next-line no-plusplus
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line no-param-reassign
    [array[i], array[j]] = [array[j], array[i]];
  }
}

// eslint-disable-next-line no-unused-vars
export default function DeckDetail({ storage }) {
  const classes = useStyles();

  const { deck: deckId } = useParams();
  const deckStorageRef = ref(storage, `decks/${deckId}`);

  const history = useHistory();
  const location = useLocation();

  const [deckContent, setDeckContent] = useState([]);
  const [onSlide, setOnSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [czechLabelShown, setCzechLabelShown] = useState(true);
  const [englishLabelShown, setEnglishLabelShown] = useState(true);
  const [pauseDurationMultiplier, setPauseDurationMultiplier] = useState(1.5);
  const [audioBlockedByBrowser, setAudioBlockedByBrowser] = useState(false);

  useEffect(() => {
    getDownloadURL(ref(deckStorageRef, 'deck.csv'))
      .then((deckCsv) => {
        readRemoteFile(deckCsv, {
          download: true,
          complete: async (results) => {
            const newDeckContent = results.data.slice(0, -1);
            let deckShuffle = false;

            getDownloadURL(ref(deckStorageRef, 'props.json'))
              .then((deckPropsUrl) => {
                fetch(deckPropsUrl)
                  .then((r) => r.json())
                  .then((deckProps) => {
                    console.log('props found');
                    if (deckProps) {
                      if ('shuffle' in deckProps) {
                        console.log(`props shuffle: ${deckProps.shuffle}`);
                        deckShuffle = deckProps.shuffle;
                      }
                    }
                  })
                  .catch(() => false)
                  .finally(() => {
                    console.log(`final shuffle: ${deckShuffle}`);
                    if (deckShuffle) shuffleArray(newDeckContent);
                    setDeckContent(newDeckContent);
                    setIsLoading(false);
                  });
              })
              .catch((e) => {
                if (e.code === 'storage/unauthorized') {
                  history.push(`/unauthorized?from=${location.pathname}`);
                } else if (e.code === 'storage/object-not-found') {
                  console.log('no props');
                  setDeckContent(newDeckContent);
                  setIsLoading(false);
                }
              });
          },
        });
      })
      .catch((e) => {
        if (e.code === 'storage/unauthorized') {
          history.push(`/unauthorized?from=${location.pathname}`);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deckId]);

  useEffect(() => {
    let wakelock = null;
    async function getWakelock() {
      try {
        return await navigator.wakeLock.request('screen');
      } catch (err) {
        // can't get wakelock for some reason, too bad!
        console.log(`${err.name}, ${err.message}`);
        return null;
      }
    }
    getWakelock().then((returnedWakelock) => {
      if (returnedWakelock) {
        console.log('wakelock acquired');
        wakelock = returnedWakelock;
      } else console.log('could not acquire wakelock');
    });
    return function cleanup() {
      if (wakelock) {
        console.log('releasing wakelock');
        wakelock.release();
      }
    };
  }, []);

  useEffect(() => {
    if (audioBlockedByBrowser) {
      setIsPlaying(false);
    }
  }, [audioBlockedByBrowser]);

  function nextSlide() {
    if (onSlide + 1 < deckContent.length) {
      setOnSlide(onSlide + 1);
    } else {
      setIsPlaying(false);
    }
  }

  function handleResumePauseClick() {
    if (!isPlaying && audioBlockedByBrowser) {
      const audioElement = document.getElementById('audio-player');
      audioElement.play().then(() => setIsPlaying(true));
    } else {
      setIsPlaying(!isPlaying);
    }
  }

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <Box flex="1">
        <Box display="flex">
          <Box m="auto">
            {isLoading ? (
              <LoadingSlide />
            ) : (
              <Slide
                slideContent={deckContent[onSlide]}
                deckId={deckId}
                onSlideFinished={nextSlide}
                isSlidePlaying={isPlaying}
                czechLabelShown={czechLabelShown}
                englishLabelShown={englishLabelShown}
                pauseDurationMultiplier={pauseDurationMultiplier}
                deckStorageRef={deckStorageRef}
                setAudioBlockedByBrowser={setAudioBlockedByBrowser}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Controls
        isPlaying={isPlaying}
        onResumePauseClick={handleResumePauseClick}
        onSlide={isLoading ? 1 : onSlide}
        maxSlide={isLoading ? 1 : deckContent.length}
        onSlideChange={(_, slide) => setOnSlide(slide)}
        czechLabelShown={czechLabelShown}
        setCzechLabelShown={setCzechLabelShown}
        englishLabelShown={englishLabelShown}
        setEnglishLabelShown={setEnglishLabelShown}
        pauseDurationMultiplier={pauseDurationMultiplier}
        setPauseDurationMultiplier={setPauseDurationMultiplier}
      />
    </Box>
  );
}
