/* eslint-disable */
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ButtonBase, Collapse, Container, Typography } from '@mui/material';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import Deck from './Deck';
import LoadingDeck from './LoadingDeck';

const useStyles = makeStyles((theme) => ({
  decksList: {
    paddingTop: theme.spacing(2),
  },
  deckWrapper: {
    paddingBottom: theme.spacing(2),
  },
  archiveTitleWrapper: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  archiveTitle: {
    width: '100%',
    padding: theme.spacing(1),
  },
  archiveTitleIcon: {
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

export default function DecksList({ firestore }) {
  const classes = useStyles();

  const [authUser] = useAuthState(firebase.auth());
  const [publicDecks, publicDecksLoading] = useCollection(
    firestore.collection('decks').where('premium', '==', false),
  );

  const [user, userLoading] = useDocumentData(
    !!authUser ? firestore.collection('users').doc(authUser.uid) : null,
  );

  const [premiumDecks, setPremiumDecks] = useState([]);
  const [premiumDecksLoading, setPremiumDecksLoading] = useState(true);

  const [activeDecksIds, setActiveDecksIds] = useState([]);
  const [isArchiveCollapsed, setIsArchiveCollapsed] = useState(true);

  const [loading, setLoading] = useState(true);

  function archiveDeck(deckIdToArchive) {
    firestore
      .collection('users')
      .doc(authUser.uid)
      .update({
        archived: [...new Set([...user.archived, deckIdToArchive])],
      });
  }

  function unarchiveDeck(deckIdToUnarchive) {
    firestore
      .collection('users')
      .doc(authUser.uid)
      .update({
        archived: user.archived.filter((deckId) => deckId !== deckIdToUnarchive),
      });
  }

  useEffect(() => {
    if (user) {
      async function loadPremium() {
        const promises = [];
        for (const deck of user.premiumDecks) {
          const doc = firestore.collection('decks').doc(deck).get();
          promises.push(doc);
        }
        const newPremiumDecks = await Promise.all(promises)
        setPremiumDecks(newPremiumDecks.filter((deck) => deck.exists));
      }
      loadPremium()
        .then((r) => {
          setPremiumDecksLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [user, userLoading]);

  useEffect(() => {
    if (!publicDecksLoading && !userLoading) {
      if (user) {
        if (premiumDecksLoading) {
          setLoading(true);
        } else {
          setActiveDecksIds(
            [...publicDecks.docs, ...premiumDecks]
              .filter((deck) => !user.archived.includes(deck.data().uid))
              .map((deck) => deck.data().uid),
          );
          setLoading(false);
        }
      } else {
        setActiveDecksIds(publicDecks.docs.map((deck) => deck.data().uid));
        setLoading(false);
      }
    }
  }, [publicDecks, user, publicDecksLoading, premiumDecksLoading, premiumDecks, userLoading]);

  return (
    <Container className={classes.decksList}>
      {loading ? (
        <>
          {[...Array(4).keys()].map((key) => (
            <div className={classes.deckWrapper} key={key}>
              <LoadingDeck />
            </div>
          ))}
        </>
      ) : (
        <>
          {[...publicDecks.docs, ...premiumDecks]
            .filter((deck) => activeDecksIds.includes(deck.data().uid))
            .sort((a, b) =>
              `${a.data().category} ${a.data().name}`.localeCompare(
                `${b.data().category} ${b.data().name}`,
              ),
            )
            .map((deck) => (
              <div className={classes.deckWrapper} key={deck.data().uid}>
                <Deck
                  category={deck.data().category}
                  name={deck.data().name}
                  deckId={deck.data().uid}
                  isArchived={false}
                  onArchive={() => archiveDeck(deck.data().uid)}
                  canArchive={!!user}
                />
              </div>
            ))}
          {!!user && user.archived.length !== 0 && (
            <>
              <div className={classes.archiveTitleWrapper}>
                <ButtonBase
                  className={classes.archiveTitle}
                  onClick={() => setIsArchiveCollapsed(!isArchiveCollapsed)}
                >
                  <Typography variant="h5" component="h2">
                    Archivované balíčky
                  </Typography>
                  <div className={classes.archiveTitleIcon}>
                    {isArchiveCollapsed ? <ExpandMore /> : <ExpandLess />}
                  </div>
                </ButtonBase>
              </div>

              <Collapse in={!isArchiveCollapsed} timeout="auto">
                {[...publicDecks.docs, ...premiumDecks]
                  .filter((deck) => !activeDecksIds.includes(deck.data().uid))
                  .sort((a, b) =>
                    `${a.data().category} ${a.data().name}`.localeCompare(
                      `${b.data().category} ${b.data().name}`,
                    ),
                  )
                  .map((deck) => (
                    <div className={classes.deckWrapper} key={deck.data().uid}>
                      <Deck
                        category={deck.data().category}
                        name={deck.data().name}
                        deckId={deck.data().uid}
                        isArchived
                        onArchive={() => unarchiveDeck(deck.data().uid)}
                        canArchive={!!user}
                      />
                    </div>
                  ))}
              </Collapse>
            </>
          )}
        </>
      )}
    </Container>
  );
}
