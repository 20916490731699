/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { CardActionArea } from '@mui/material';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

export default function Deck({
  category, name, deckId, isArchived, onArchive, canArchive,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardActionArea component={Link} to={`decks/${deckId}`}>
        <CardContent>
          <Typography variant="overline" color="textSecondary">
            {category}
          </Typography>
          <Typography variant="h5" component="h2">
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="secondary" component={Link} to={`decks/${deckId}`}>
          Spustit
        </Button>
        {canArchive && (
          <Button size="small" color="secondary" onClick={onArchive}>
            {isArchived ? 'Vyjmout z archivu' : 'Archivovat'}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

Deck.propTypes = {
  category: PropTypes.string.isRequired,
  isArchived: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onArchive: PropTypes.func.isRequired,
  deckId: PropTypes.string.isRequired,
};
