/* eslint-disable no-extra-boolean-cast */
import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { getStorage } from 'firebase/storage';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import Layout from './Components/Layout';
import DecksList from './Components/Pages/DecksList';
import Error404 from './Components/Pages/Error404';
import DeckDetail from './Components/Pages/DeckDetail';
import Unauthorized from './Components/Pages/Unauthorized';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const firebaseConfig = {
  apiKey: 'AIzaSyCo6AzypzGCAubxyktG8a3f7A2jvB7cPew',
  authDomain: 'shadowloop-firebase.firebaseapp.com',
  projectId: 'shadowloop-firebase',
  storageBucket: 'shadowloop-firebase.appspot.com',
  messagingSenderId: '177841367667',
  appId: '1:177841367667:web:d7d71f9155dbd9de8a1806',
  measurementId: 'G-7GECHMWSF9',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
const storage = getStorage();

function App() {
  const [authUser] = useAuthState(firebase.auth());

  useEffect(() => {
    if (!!authUser) {
      const userRef = firestore.collection('users').doc(authUser.uid);

      userRef.get().then((userSnapshot) => {
        if (!userSnapshot.exists) {
          const userData = {
            uid: authUser.uid,
            displayName: authUser.displayName,
            email: authUser.email,
            premiumDecks: ['dummy'],
            archived: [],
          };
          userRef.set(userData);
        }
      });
    }
  }, [authUser]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Layout authUser={authUser}>
            <Switch>
              <Route exact path="/">
                <DecksList firestore={firestore} />
              </Route>
              <Route exact path="/decks">
                <Redirect to="/" />
              </Route>
              <Route exact path="/decks/:deck">
                <DeckDetail storage={storage} />
              </Route>
              <Route exact path="/unauthorized">
                <Unauthorized />
              </Route>
              <Route>
                <Error404 />
              </Route>
            </Switch>
          </Layout>
        </ThemeProvider>
      </StyledEngineProvider>
    </Router>
  );
}

export default App;
