import React from 'react';
import firebase from 'firebase/compat/app';
import { Typography } from '@mui/material';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useHistory, useLocation } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Unauthorized() {
  const query = useQuery();
  const history = useHistory();

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        history.push(query.get('from'));
      },
    },
  };

  const [authUser, authLoading] = useAuthState(firebase.auth());

  return (
    <>
      {!authLoading && authUser && (
        <div style={{ paddingTop: '16px' }}>
          <Typography variant="h5" align="center">
            K tomuto balíčku nemáte přístup.
          </Typography>
          <Link to="/">
            <p align="center">Zpět na hlavní stránku</p>
          </Link>
        </div>
      )}
      {!authLoading && !authUser && (
        <div style={{ paddingTop: '16px' }}>
          <Typography variant="h5" align="center">
            K tomuto balíčku nemáte přístup.
          </Typography>
          <p align="center">Zkuste se přihlásit:</p>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        </div>
      )}
    </>
  );
}
