/* eslint-disable react/prop-types,react/jsx-no-bind */
import PropTypes, { string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ToggleButton } from '@mui/material';
import { Repeat as RepeatIcon } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { ref, getDownloadURL } from 'firebase/storage';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    maxWidth: '100vw',
    overflowWrap: 'anywhere',
    hyphens: 'auto',
  },
  card: {
    width: 'fit-content',
  },
  mediaWrapper: {
    width: '100%',
  },
  media: {
    maxWidth: '100%',
  },
  loopButton: {
    float: 'right',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

function Image({
  className, alt, image, deckStorageRef,
}) {
  const newest = useRef(image);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    getDownloadURL(ref(deckStorageRef, image)).then((newImageUrl) => {
      if (newest.current === image) {
        setImageUrl(newImageUrl);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  useEffect(() => {
    newest.current = image;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return <img className={className} src={imageUrl} alt={alt} />;
}

function Audio({
  // eslint-disable-next-line no-unused-vars
  audio, deckStorageRef, playing, onEnded, onDuration, setAudioBlockedByBrowser,
}) {
  const newest = useRef(audio);
  const [audioUrl, setAudioUrl] = useState();

  const audioElement = document.getElementById('audio-player');

  async function playAudio() {
    if (!audioElement.paused) {
      console.log('play called put already playing');
      return;
    }

    try {
      console.log('play called');
      await audioElement.play();
      setAudioBlockedByBrowser(false);
    } catch (e) {
      if (e.name === 'NotAllowedError') {
        console.log('autoplay block');
        setAudioBlockedByBrowser(true);
      } else {
        console.log('could not play - invalid source');
      }
    }
  }

  function durationKnown() {
    onDuration(audioElement.duration);
    console.log(audioElement.duration);
  }

  useEffect(() => {
    audioElement.addEventListener('ended', onEnded);
    audioElement.addEventListener('loadedmetadata', durationKnown);
    return () => {
      audioElement.removeEventListener('ended', onEnded);
      audioElement.removeEventListener('loadedmetadata', durationKnown);

      audioElement.pause();
      audioElement.src = '';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAudioUrl('');
    getDownloadURL(ref(deckStorageRef, audio)).then((newAudioUrl) => {
      if (newest.current === audio) {
        setAudioUrl(newAudioUrl);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);

  useEffect(() => {
    newest.current = audio;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);

  useEffect(() => {
    if (audioUrl) {
      console.log(`new audio url: ${audioUrl}`);
      audioElement.src = audioUrl;
      audioElement.load();
      playAudio();
      // audioElement.addEventListener('canplaythrough', scheduleAudio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl]);

  useEffect(() => {
    if (playing) {
      playAudio();
    } else {
      audioElement.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);

  return <></>;
}

export default function Slide({
  slideContent,
  onSlideFinished,
  isSlidePlaying,
  czechLabelShown,
  englishLabelShown,
  pauseDurationMultiplier,
  deckStorageRef,
  setAudioBlockedByBrowser,
}) {
  const classes = useStyles();

  const pauseDuration = useRef(2);

  const [englishText, czechText, audio, image] = slideContent;

  const [timesPlayed, setTimesPlayed] = useState(0);
  const timesPlayedRef = useRef(timesPlayed);

  const [isSlideRepeating, setIsSlideRepeating] = useState(false);

  const [isAudioPlaying, setIsAudioPlaying] = useState(true);
  const [isAudioPaused, setIsAudioPaused] = useState(false);

  const timeoutRef = useRef(0);

  useEffect(() => {
    setTimesPlayed(0);
    timesPlayedRef.current = 0;
    setIsAudioPaused(false);
    clearTimeout(timeoutRef.current);
  }, [slideContent]);

  useEffect(() => {
    setIsAudioPlaying(isSlidePlaying && !isAudioPaused);
  }, [isSlidePlaying, isAudioPaused]);

  useEffect(() => {
    if (isSlidePlaying && timesPlayedRef.current > 2 && !isSlideRepeating) {
      onSlideFinished();
      document.getElementById('audio-player').src = '';
    }
  }, [isSlidePlaying, isSlideRepeating, onSlideFinished, timesPlayed]);

  function onAudioFinished() {
    setIsAudioPaused(true);
    timeoutRef.current = setTimeout(() => {
      timesPlayedRef.current += 1;
      setTimesPlayed(timesPlayedRef.current);
      setIsAudioPaused(false);
    }, pauseDuration.current * 1000 * pauseDurationMultiplier);
  }

  return (
    <div className={classes.wrapper}>
      <Card className={classes.card}>
        <Box className={classes.mediaWrapper} display="flex" justifyContent="center">
          <Image
            className={classes.media}
            image={image}
            alt={englishText}
            deckStorageRef={deckStorageRef}
          />
        </Box>
        <CardContent>
          <div className={classes.loopButton}>
            <ToggleButton
              value="repeat"
              selected={isSlideRepeating}
              onChange={() => setIsSlideRepeating(!isSlideRepeating)}
            >
              <RepeatIcon />
            </ToggleButton>
          </div>
          {(englishLabelShown || czechLabelShown) && (
            <>
              <Typography gutterBottom variant="h5" component="h2">
                {englishLabelShown ? englishText : czechText}
              </Typography>
              {englishLabelShown && czechLabelShown && (
                <Typography variant="body2" color="textSecondary" component="p">
                  {czechText}
                </Typography>
              )}
            </>
          )}
        </CardContent>
        <Audio
          audio={audio}
          deckStorageRef={deckStorageRef}
          playing={isAudioPlaying}
          onEnded={onAudioFinished}
          onDuration={(t) => {
            pauseDuration.current = t;
          }}
          setAudioBlockedByBrowser={setAudioBlockedByBrowser}
        />
      </Card>
    </div>
  );
}

Slide.propTypes = {
  czechLabelShown: PropTypes.bool.isRequired,
  englishLabelShown: PropTypes.bool.isRequired,
  isSlidePlaying: PropTypes.bool.isRequired,
  onSlideFinished: PropTypes.func.isRequired,
  pauseDurationMultiplier: PropTypes.number.isRequired,
  slideContent: PropTypes.arrayOf(string).isRequired,
};
